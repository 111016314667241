import { inject, ref } from 'vue'
import { store } from '@/store'
import { useToggle } from '@/js/use/toggle'

export function useLegend (props) {
  const map = inject('map')
  const legend = ref([
    {
      voltage: 220000,
      title: 'Напряжение 220 кВ',
      color: '#07C5C5',
      active: true
    },
    {
      voltage: 500000,
      title: 'Напряжение 500 кВ',
      color: '#FF8A00',
      active: true
    }
  ])
  const objectToArray = (obj) => {
    return Object.values(obj).map(item => {
      return (typeof item === 'object') ? Object.values(item) : item
    })
  }
  const toggleLayers = (index) => {
    legend.value[index].active = !legend.value[index].active
    const filtered = legend.value.filter(item => !item.active)
    props.layersFilter.forEach(layer => {
      const filters = []
      filtered.forEach(item => {
        filters.push(['!=', ['get', 'voltage'], item.voltage])
      })
      if (layer.filter) {
        filters.push(objectToArray(layer.filter))
      }
      if (filters.length > 1) {
        map.value.setFilter(layer.id, ['all'].concat(filters))
      } else if (filters.length === 1) {
        map.value.setFilter(layer.id, filters[0])
      } else {
        map.value.setFilter(layer.id, null)
      }
    })
    // Для кластеров
    const voltage = filtered.map(item => item.voltage)
    const stations = store.getters.getCollection('stationsPoints')
    stations.features = stations.features.filter(feature => !voltage.includes(feature.properties.voltage))
    map.value.getSource('stationsPoints').setData(stations)
  }
  return {
    legend,
    toggleLayers,
    ...useToggle()
  }
}
