import { reactive } from 'vue'

export function useDropdown () {
  const dropdown = reactive({
    open: false,
    active: 0,
    list: [
      {
        title: 'Объкты ЭСХ',
        mode: 'objects'
      },
      {
        title: 'Искать на карте',
        mode: 'map'
      },
      // {
      //   title: 'Название организации',
      //   mode: 'company'
      // },
      {
        title: 'Кадастровый номер земельного участка',
        mode: 'land'
      }
    ]
  })

  const dropdownToggle = () => {
    dropdown.open = !dropdown.open
  }
  const dropdownSelect = (index) => {
    dropdown.active = index
    dropdown.open = false
  }
  return {
    dropdown, dropdownToggle, dropdownSelect
  }
}
