<template lang="pug">
#app
  Header(v-if="isAuthenticated")
  router-view
</template>

<script>
import Header from '@/components/Header'
import { computed, onBeforeMount } from 'vue'
import { store } from '@/store'
import { USER_REQUEST } from '@/store/actions/user'
import router from '@/router'

export default {
  components: {
    Header
  },
  setup () {
    const isAuthenticated = computed(() => store.getters.isAuthenticated)
    const userRequest = onBeforeMount(() => {
      if (store.getters.isAuthenticated) {
        store.dispatch(USER_REQUEST)
      }
    })
    const unsubscribe = store.subscribe((mutation, state) => {
      if (mutation.type === 'AUTH_LOGOUT') {
        router.push('/login')
      }
    })
    return {
      isAuthenticated,
      userRequest,
      unsubscribe
    }
  }
}
</script>

<style lang="sass">
// Not remove
</style>
