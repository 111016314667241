<template lang="pug">
Measure(v-if="measure && !route" :measure="measure")
Route(v-if="route" @route-close="routeToggle")
.map__control.mapControl(:style="{ marginLeft: mapPaddingLeft/2 + 'px' }")
  .mapControl__groupe
    .mapControl__item.mapControl__item--text(
      @click="toggleSatellite(true)"
      :class="[showSatellite !== 'none' ? 'active' : '']"
    ) Спутник
    .mapControl__item.mapControl__item--text(
      @click="toggleSatellite(false)"
      :class="[showSatellite === 'none' ? 'active' : '']"
      ) Схема
  .mapControl__groupe
    .mapControl__item(title="Приблизить" @click="zoomIn")
      .mapControl__icon.mapControl__icon--zoomIn
    .mapControl__item(title="Отдалить" @click="zoomOut")
      .mapControl__icon.mapControl__icon--zoomOut
    .mapControl__item(title="Повернуть карту на север" @click="bearingSouth")
      .mapControl__icon.mapControl__icon--orient
  .mapControl__groupe
    .mapControl__item(title="Измерить расстояние" @click="[ drawLine(), routeOff() ]")
      .mapControl__icon.mapControl__icon--distance
    .mapControl__item(title="Измерить площадь" @click="[ drawPoly(), routeOff() ]")
      .mapControl__icon.mapControl__icon--square
    .mapControl__item(title="Координаты" @click="[ coordPoint(), routeOff() ]")
      .mapControl__icon.mapControl__icon--coord
    .mapControl__item(title="Удалить измерение" @click="[ trash(), routeOff() ]")
      .mapControl__icon.mapControl__icon--remove
    .mapControl__item(title="Маршрут" @click="routeToggle()" :class="{ active: route }")
      .mapControl__icon.mapControl__icon--route
    .mapControl__item(title="Напечатать" @click="print")
      .mapControl__icon.mapControl__icon--print
</template>

<script>
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import Measure from '@/components/Measure'
import { useControl } from '@/js/use/control'
import Route from '@/components/Route'

export default {
  components: {
    Route,
    Measure
  },
  setup () {
    return {
      ...useControl()
    }
  }
}
</script>
