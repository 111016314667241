import { createStore } from 'vuex'
import user from './modules/user'
import auth from './modules/auth'
import data from './modules/data'

const debug = process.env.NODE_ENV !== 'production'

export const store = createStore({
  modules: {
    user,
    auth,
    data
  },
  strict: debug
})
