import { getCurrentInstance, inject, reactive } from 'vue'
import { store } from '@/store'
import { fieldsList } from '@/js/map/fieldsList'
import { DATA_OBJECT, DATA_PIVOT_LIST } from '@/store/actions/data'
import { downloadFile } from '@/js/utils/api'
import { bbox } from '@turf/turf'

export const useSidebar = () => {
  const { emit } = getCurrentInstance()
  const map = inject('map')
  const sidebar = reactive({
    open: false,
    data: null,
    search: null
  })
  const mapPaddingLeft = inject('mapPaddingLeft')
  const addMapPaddingLeft = inject('addMapPaddingLeft')
  const removeMapPaddingLeft = inject('removeMapPaddingLeft')
  store.subscribe((mutation) => {
    if (mutation.type === 'DATA_SET_OBJECT') {
      sidebar.search = null
      sidebar.data = fieldsList(mutation.payload[0])
      sidebar.open = true
      addMapPaddingLeft()
    }
    if (mutation.type === 'DATA_SEARCH') {
      sidebar.data = null
      sidebar.search = listSearchResult(mutation.payload)
      sidebar.open = true
      addMapPaddingLeft()
    }
  })
  map.value.on('click', e => {
    if (sidebar.open) {
      sidebar.open = false
      removeMapPaddingLeft()
    }
  })
  const openTable = (id) => {
    store.dispatch(DATA_PIVOT_LIST, id)
  }
  const infoObject = ({ id, collection, bbox }) => {
    store.dispatch(DATA_OBJECT, { type: collection, id })
    setTimeout(() => {
      map.value.fitBounds(bbox, {
        padding: {
          top: 80,
          bottom: 80,
          right: 80,
          left: mapPaddingLeft.value + 80
        }
      })
    }, 500)
    const layerId = collection === 'station' ? 'stations_fill' : 'powerLine_line'
    setTimeout(() => {
      const features = map.value.queryRenderedFeatures({
        layers: [layerId],
        filter: ['==', ['get', 'id'], id]
      })
      if (features.length) {
        emit('featureActive', {
          source: features[0].layer.source,
          id: features[0].id
        })
      }
    }, 1000)
  }
  const clearFeature = () => {
    sidebar.open = false
    sidebar.data = null
    sidebar.search = null
    removeMapPaddingLeft()
    emit('featureActive', null)
  }
  const download = url => {
    downloadFile(url)
  }
  return {
    sidebar,
    download,
    infoObject,
    clearFeature,
    openTable
  }
}

function listSearchResult (data) {
  const types = {
    line: 'Линия электропередач',
    station: 'Электростанция',
    substation: 'Подстанция'
  }
  return data.map(feature => {
    const typeObject = feature.properties._t || 'line'
    const bboxAr = bbox(feature)
    return {
      id: feature.properties.id,
      name: feature.properties.name,
      type: types[typeObject],
      bbox: [
        [bboxAr[0], bboxAr[1]],
        [bboxAr[2], bboxAr[3]]
      ],
      collection: (feature.properties._t) ? 'stations' : 'lines'
    }
  })
}
