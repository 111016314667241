import { store } from '@/store'
import { DATA_GET } from '@/store/actions/data'

export function layersList () {
  const sources = {
    municipals: {
      collectionName: 'region',
      options: {
        type: 'geojson',
        data: null
      },
      layers: [
        {
          id: 'region_line',
          type: 'line',
          source: null,
          filter: ['==', ['get', 'is_munitipal'], 0],
          paint: {
            'line-dasharray': [2, 1],
            'line-color': '#425CFF',
            'line-width': 2,
            'line-opacity': 1
          }
        },
        {
          id: 'municipals_line',
          type: 'line',
          source: null,
          filter: ['==', ['get', 'is_munitipal'], 1],
          paint: {
            'line-dasharray': [2, 1],
            'line-color': '#425CFF',
            'line-width': [
              'interpolate', ['linear'], ['zoom'],
              5, 1,
              10, 2
            ],
            'line-opacity': [
              'interpolate', ['linear'], ['zoom'],
              5, 0.5,
              10, 1
            ]
          }
        }
      ]
    },
    zu: {
      collectionName: 'zu',
      options: {
        type: 'geojson',
        data: null,
        generateId: true
      },
      layers: [
        {
          id: 'zu_fill',
          type: 'fill',
          source: null,
          options: {
            hover: true,
            popup: {
              cad: 'Кадастровый номер: ',
              coordinates: 'Координаты'
            } // first prop - title popup
          },
          layout: {},
          paint: {
            'fill-color': '#FFFA7E',
            'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.3, 0.1]
          },
          minzoom: 13
        },
        {
          id: 'zu_line',
          type: 'line',
          source: null,
          layout: {},
          paint: {
            'line-color': '#FFFA7E',
            'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 2, 1]
          },
          minzoom: 13
        }
      ]
    },
    stations: {
      collectionName: 'stations',
      filter: true, // для отображения нужного напряжения через легенду
      options: {
        type: 'geojson',
        data: null,
        generateId: true
      },
      layers: [
        {
          id: 'stations_fill_click',
          type: 'fill',
          source: null,
          layout: {},
          paint: {
            'fill-color': '#FFFA7E',
            'fill-opacity': ['case', ['boolean', ['feature-state', 'clicked'], false], 0.6, 0]
          },
          minzoom: 11.5
        },
        {
          id: 'stations_fill',
          type: 'fill',
          source: null,
          options: {
            hover: true,
            click: 'sidebar',
            popup: {
              name: '', // заголовок; значение всатвляется перед заголовком
              coordinates: 'Координаты',
              owner: 'Организация'
            } // first prop - title popup
          },
          layout: {},
          paint: {
            'fill-color': [
              'match',
              ['get', 'voltage'],
              220_000, '#07C5C5',
              500_000, '#FF8A00',
              '#808080'
            ],
            'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.6, 0.4]
          },
          minzoom: 11.5
        },
        {
          id: 'stations_line',
          type: 'line',
          source: null,
          layout: {},
          paint: {
            'line-color': [
              'match', ['get', 'voltage'],
              220_000, '#07C5C5',
              500_000, '#FF8A00',
              '#f0f0f0'
            ],
            'line-width': 2
          },
          minzoom: 11.5
        }
      ]
    },
    powerLine: {
      collectionName: 'lines',
      filter: true, // для отображения нужного напряжения через легенду
      options: {
        type: 'geojson',
        data: null,
        generateId: true
      },
      layers: [
        {
          id: 'powerLine_line_click',
          type: 'line',
          source: null,
          options: {
            click: 'sidebar'
          },
          layout: {},
          paint: {
            'line-color': '#FFFA7E',
            'line-width': 5,
            'line-opacity': [
              'case',
              ['boolean', ['feature-state', 'clicked'], false],
              1, 0
            ]
          }
        },
        {
          id: 'powerLine_line_hover',
          type: 'line',
          source: null,
          options: {
            hover: true,
            popup: {
              name: '', // заголовок; значение всатвляется перед заголовком
              lengthPassport: 'Протяженность',
              owner: 'Организация'
            } // first prop - title popup
          },
          layout: {},
          paint: {
            // 'line-color': '#fff',
            'line-color': [
              'case',
              ['boolean',
                ['feature-state', 'clicked'],
                false
              ],
              '#FFFA7E',
              '#ffffff'
            ],
            'line-width': 5,
            'line-opacity': [
              'case',
              ['boolean', ['feature-state', 'hover'], false],
              1, 0
            ]
          }
        },
        {
          id: 'powerLine_line',
          type: 'line',
          source: null,
          layout: {},
          paint: {
            'line-color': [
              'match', ['get', 'voltage'],
              220_000, '#07C5C5',
              500_000, '#FF8A00',
              '#808080'
            ],
            'line-width': 2
          }
        },
        {
          id: 'powerLine_text',
          type: 'symbol',
          source: null,
          layout: {
            'text-field': '{name}',
            'symbol-placement': 'line',
            'text-allow-overlap': false,
            'text-font': ['Open Sans SemiBold'],
            'text-size': 12,
            'text-anchor': 'top'
          },
          paint: {
            'text-color': '#ffffff',
            'text-halo-color': 'rgba(0, 0, 0, 0.5)',
            'text-halo-width': 1
          }
        }
      ]
    },
    powerPoints: {
      collectionName: 'points',
      filter: true, // для отображения нужного напряжения через легенду
      options: {
        type: 'geojson',
        data: null,
        generateId: true
      },
      layers: [
        {
          id: 'powerPoints_points_click',
          type: 'symbol',
          source: null,
          options: {
            click: 'sidebar'
          },
          layout: {
            'icon-allow-overlap': true,
            'icon-image': 'square-selected',
            'icon-size': 1
          },
          paint: {
            'icon-opacity': ['case', ['boolean', ['feature-state', 'clicked'], false], 1, 0]
          },
          minzoom: 13
        },
        {
          id: 'powerPoints_points_hover',
          type: 'symbol',
          source: null,
          options: {
            hover: true,
            popup: {
              name: 'Опора № ', // заголовок; значение всатвляется перед заголовком
              coordinates: 'Координаты',
              owner: 'Организация'
            } // first prop - title popup
          },
          layout: {
            'icon-allow-overlap': true,
            'icon-image': 'square',
            'icon-size': 1
          },
          paint: {
            'icon-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0]
          },
          minzoom: 13
        },
        {
          id: 'powerPoints_points',
          type: 'symbol',
          source: null,
          options: {
            hover: true,
            click: 'sidebar'
          },
          layout: {
            'icon-allow-overlap': true,
            'icon-image': [
              'match', ['get', 'voltage'],
              220_000, 'square-220',
              500_000, 'square-500',
              'square-500'
            ],
            'icon-size': 1
          },
          minzoom: 13
        },
        {
          id: 'powerPoints_text',
          type: 'symbol',
          source: null,
          layout: {
            // 'text-field': '{name}',
            'text-field': [
              'case',
              ['!=', ['get', 'name'], 'null'],
              ['get', 'name'],
              ''
            ],
            'text-font': ['Open Sans SemiBold'],
            'text-size': 12,
            'text-offset': [1, 1],
            'symbol-placement': 'point',
            'text-variable-anchor': ['top', 'bottom']
          },
          paint: {
            'text-color': '#fff',
            'text-halo-color': 'rgba(0,0,0,0.5)',
            'text-halo-width': 1
          },
          minzoom: 13
        }
      ]
    },
    stationsPoints: {
      collectionName: 'stationsPoints',
      filter: true, // для отображения нужного напряжения через легенду
      options: {
        type: 'geojson',
        data: null,
        generateId: true,
        cluster: true,
        clusterMaxZoom: 13,
        clusterRadius: 30
      },
      layers: [
        {
          id: 'stationsPoints_clusters',
          type: 'circle',
          source: null,
          options: {
            hover: true,
            click: 'cluster'
          },
          filter: ['has', 'point_count'],
          paint: {
            'circle-color': '#808080',
            'circle-radius': 16,
            'circle-stroke-width': 3,
            'circle-stroke-color': ['case', ['boolean', ['feature-state', 'hover'], false], '#ffffff', 'transparent']
          }
        }, {
          id: 'stationsPoints_clusters-count',
          type: 'symbol',
          source: null,
          filter: ['has', 'point_count'],
          layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': ['Open Sans Bold'],
            'text-size': 12
          },
          paint: {
            'text-color': '#fff',
            'text-halo-color': 'rgba(50,50,50,0.2)',
            'text-halo-width': 1
          }
        },
        {
          id: 'stationsPoints_points',
          type: 'circle',
          source: null,
          options: {
            hover: true,
            popup: {
              name: '',
              coordinates: 'Координаты',
              owner: 'Организация'
            }, // first prop - title popup
            click: 'sidebar'
          },
          filter: ['!', ['has', 'point_count']],
          paint: {
            'circle-color': [
              'match', ['get', 'voltage'],
              220_000, '#07C5C5',
              500_000, '#FF8A00',
              '#808080'
            ],
            'circle-radius': [
              'interpolate',
              ['linear'],
              ['zoom'],
              10, 14,
              18, 20
            ],
            'circle-stroke-width': ['case', ['boolean', ['feature-state', 'hover'], false], 3, 0],
            'circle-stroke-color': '#ffffff'
          }
        },
        {
          id: 'stationsPoints_icons',
          type: 'symbol',
          source: null,
          filter: ['!', ['has', 'point_count']],
          paint: {
            'text-color': '#fff'
          },
          layout: {
            'icon-allow-overlap': true,
            'icon-image': ['get', '_t'],
            'icon-size': [
              'interpolate',
              ['linear'],
              ['zoom'],
              10, 0.35,
              18, 0.5
            ]
          }
        }
      ]
    }
  }
  return new Promise((resolve, reject) => {
    store.dispatch(DATA_GET).then(() => {
      Object.keys(sources).forEach(nameSource => {
        sources[nameSource].options.data = store.getters.getCollection(sources[nameSource].collectionName)
        // eslint-disable-next-line no-return-assign
        sources[nameSource].layers.map(layer => layer.source = nameSource)
      })
      resolve(sources)
    }).catch(err => {
      reject(err)
      throw err
    })
  })
}
