import { createWebHistory, createRouter } from 'vue-router'
import Auth from '@/views/Auth.vue'
import Map from '@/views/Map.vue'
import { store } from '@/store'

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/login')
}

const routes = [
  {
    path: '/login',
    name: 'Вход',
    component: Auth,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/',
    name: 'Карта',
    component: Map,
    beforeEnter: ifAuthenticated
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
