import { computed, getCurrentInstance, inject, reactive } from 'vue'
import { useDropdown } from '@/js/use/dropdown'
import { store } from '@/store'
import mapboxgl from 'mapbox-gl'
import { bbox } from '@turf/turf'
import { DATA_SEARCH } from '@/store/actions/data'

const osm = {}
getOsmPresets().then((res) => {
  osm.value = res
})

export function useSearch () {
  const { emit } = getCurrentInstance()
  const map = inject('map')
  const marker = new mapboxgl.Marker()
  const mapPaddingLeft = inject('mapPaddingLeft')
  const { dropdown, dropdownToggle, dropdownSelect } = useDropdown()
  const inputSearch = reactive({
    minLength: 4,
    keywords: '',
    results: [],
    open: false
  })
  const dropdownSelected = computed(() => {
    inputSearch.keywords = ''
    inputSearch.results = []
    return dropdown.list[dropdown.active]
  })
  const searchMap = async () => {
    if (inputSearch.keywords.length < inputSearch.minLength) {
      inputSearch.results = []
      return
    }
    switch (dropdownSelected.value.mode) {
      case 'map':
        inputSearch.results = await getResultOsmSearch(inputSearch.keywords)
        break
      case 'objects':
        inputSearch.results = getResultObject(inputSearch.keywords)
        break
      case 'land':
        inputSearch.results = getResultLand(inputSearch.keywords)
        break
    }
    inputSearch.open = true
  }
  const searchClickResult = (index) => {
    setTimeout(() => {
      map.value.fitBounds(inputSearch.results[index].bbox, {
        padding: { left: mapPaddingLeft.value }
      })
    }, 500)
    if (inputSearch.results[index].point) {
      marker
        .setLngLat(inputSearch.results[index].point)
        .addTo(map.value)
    }
    if (dropdownSelected.value.mode === 'objects') {
      emit('infoObject', inputSearch.results[index])
    } else {
      emit('clearFeature', null)
    }
    inputSearch.open = false
  }
  const searchEnterResult = () => {
    if (inputSearch.keywords.length < inputSearch.minLength) {
      inputSearch.results = []
      return
    }
    if (!inputSearch.results.length) {
      return
    }
    inputSearch.open = false
    if (dropdownSelected.value.mode === 'map' || dropdownSelected.value.mode === 'land') {
      map.value.fitBounds(inputSearch.results[0].bbox, {
        padding: { left: mapPaddingLeft.value }
      })
      if (inputSearch.results[0].point) {
        marker
          .setLngLat(inputSearch.results[0].point)
          .addTo(map.value)
      }
      emit('clearFeature')
      inputSearch.keywords = inputSearch.results[0].name || inputSearch.results[0].address
    } else if (dropdownSelected.value.mode === 'objects') {
      store.dispatch(DATA_SEARCH, fetchResultObgect(inputSearch.keywords))
    }
    map.value.on('click', e => {
      marker.remove()
    })
  }
  return {
    dropdown,
    dropdownToggle,
    dropdownSelect,
    dropdownSelected,
    inputSearch,
    searchMap,
    searchClickResult,
    searchEnterResult
  }
}
function fetchResultObgect (keywords) {
  return store.getters.searchObjects(keywords)
}
function getResultLand (keywords) {
  const searchResults = []
  const features = store.getters.searchLand(keywords)
  features.forEach(feature => {
    const bboxAr = bbox(feature)
    searchResults.push({
      id: feature.properties.id,
      name: feature.properties.cad,
      collection: 'zu',
      bbox: [
        [bboxAr[0], bboxAr[1]],
        [bboxAr[2], bboxAr[3]]
      ]
    })
  })
  return searchResults
}
function getResultObject (keywords) {
  const searchResults = []
  const features = store.getters.searchObjects(keywords)
  features.length = features.length > 8 ? 8 : features.length
  features.forEach(feature => {
    const bboxAr = bbox(feature)
    searchResults.push({
      id: feature.properties.id,
      name: feature.properties.name,
      // point: centroid(feature).geometry.coordinates,
      collection: feature.properties._t ? 'stations' : 'lines',
      bbox: [
        [bboxAr[0], bboxAr[1]],
        [bboxAr[2], bboxAr[3]]
      ]
    })
  })
  return searchResults
}
async function getResultOsmSearch (keywords) {
  const searchResults = []
  const response = await fetch(
    `https://nominatim.openstreetmap.org/search?q=${keywords}&format=json&limit=3&viewbox=84.4478947,52.1627035,89.4026437,56.8344202&bounded=1&addressdetails=1`
  )
  const result = await response.json()
  await result.forEach(item => {
    const typeOSM = item.class && item.type
      ? item.class + '/' + item.type
      : item.class && !item.type
        ? item.class : item.type
    searchResults.push({
      type: osm.value.presets[typeOSM].name ?? null,
      address: addressToString(item.address),
      point: [item.lon, item.lat],
      bbox: [
        [item.boundingbox[2], item.boundingbox[0]],
        [item.boundingbox[3], item.boundingbox[1]]
      ]
    })
  })
  return searchResults
}

async function getOsmPresets () {
  return await fetch('/osm-types.json')
    .then(response => response.json())
}
function addressToString (address) {
  ['state', 'region', 'municipality', 'code', 'country_code', 'country', 'postcode']
    .forEach(item => delete address[item])
  return Object.values(address).join(', ')
}
