import { inject, reactive, watch } from 'vue'
import { store } from '@/store'
import { downloadFile } from '@/js/utils/api'

export const useTable = () => {
  const map = inject('map')
  const mapPaddingLeft = inject('mapPaddingLeft')
  const table = reactive({
    open: false,
    activeRow: null,
    turn: false,
    title: null,
    data: null,
    files: []
  })
  const closeTabel = () => {
    table.open = false
    table.turn = false
    table.activeRow = null
  }
  const turnTabel = () => { table.turn = false }
  store.subscribe((mutation) => {
    if (mutation.type === 'DATA_PIVOT_LIST') {
      const { files, supports } = mutation.payload
      table.files = files
      const lineId = supports[0][0].properties.power_line_id
      // eslint-disable-next-line no-const-assign
      table.open = true
      table.title = store.getters.getObjectById(lineId, 'lines').properties.name
      table.data = supports
    }
    if (mutation.type === 'DATA_GET') {
      closeTabel()
    }
  })
  watch(mapPaddingLeft, (mapPaddingLeft) => {
    if (mapPaddingLeft === 0) {
      closeTabel()
    }
  })
  const flyToPivot = (id) => {
    const pivot = store.getters.getObjectById(id, 'points')
    setTimeout(() => {
      table.activeRow = id
      map.value.flyTo({
        padding: { left: mapPaddingLeft.value },
        center: pivot.geometry.coordinates,
        zoom: 16.5
      })
    }, 500)
    table.turn = true
  }
  map.value.on('click', e => {
    closeTabel()
  })
  return {
    table,
    closeTabel,
    turnTabel,
    flyToPivot,
    downloadFile
  }
}
