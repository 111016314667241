<template lang="pug">
.map
  transition(name="fade")
    .my-toaster(v-if="loading")
      .loading.loading--s30
        .loading__animation
          div
      | Загрузка...
  slot(v-if="map")
    transition(name="fade")
      div(v-if="dataLoading")
        Legend(:layersFilter="layersFilter")
        Sidebar(@feature-active="featureActive")
        Control
    Table
  .map__container#map
</template>

<script>
import { useMap } from '@/js/use/map'
import 'mapbox-gl/dist/mapbox-gl.css'
import Legend from '@/components/Legend'
import Control from '@/components/Control'
import Sidebar from '@/components/Sidebar'
import Table from '@/components/Table'
import { getCurrentInstance, ref } from 'vue'
import { store } from '@/store'
export default {
  components: {
    Control,
    Legend,
    Sidebar,
    Table
  },
  setup () {
    const $toast = getCurrentInstance().ctx.$toast
    const loading = ref(true)
    store.subscribe(mutation => {
      if (mutation.type === 'DATA_GET') {
        loading.value = true
      }
      if (mutation.type === 'DATA_SUCCESS' || mutation.type === 'DATA_ERROR') {
        loading.value = false
      }
      if (mutation.type === 'DATA_ERROR') {
        setTimeout(() => {
          $toast.error('Ошибка загрузки данных')
        }, 300)
      }
    })
    return {
      loading,
      ...useMap({ container: 'map' })
    }
  }
}
</script>
