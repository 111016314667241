<template lang="pug">
slot(v-if="table.turn")
  .table-turn
    .table-turn__turn(@click="turnTabel")
      svg(width="12" height="20" viewBox="0 0 12 20")
        path(d="M0.93934 0.93934C0.353553 1.52513 0.353554 2.47487 0.93934 3.06066L7.87868 10L0.939341 16.9393C0.353554 17.5251 0.353554 18.4749 0.939341 19.0607C1.52513 19.6464 2.47487 19.6464 3.06066 19.0607L11.0607 11.0607C11.342 10.7794 11.5 10.3978 11.5 10C11.5 9.60217 11.342 9.22064 11.0607 8.93934L3.06066 0.93934C2.47487 0.353553 1.52513 0.353553 0.93934 0.93934Z" fill="#00AB59")
transition(name="fade")
  .table(v-if="table.open" :class="{turn: table.turn}")
    .table__close(@click="closeTabel")
      svg(width="24" height="24" viewBox="0 0 24 24")
        path(d="M10.2015 12.0853L0 1.95483L1.94324 0L12.1574 10.143L22.0568 0.312637L24 2.26747L14.1133 12.0853L23.8283 21.7325L21.8851 23.6874L12.1574 14.0275L2.11494 24L0.171698 22.0452L10.2015 12.0853Z" fill="#00AB59")
    h2 Поопорная ведомость
    perfect-scrollbar
      .table__container
        h3 {{ table.title }}
        .table__files(v-if="table.files.length")
          .table__file(v-for="(file, i) in table.files" :key="i")
            a(:href="file.href" @click.prevent="downloadFile(file)") {{ file.name }}
        .table__item(v-for="(list, i) of table.data" :key="i")
          h3(v-if="i") Отпайка № {{i}}
          table
            thead
              tr
                th №
                th Имя опоры
                th Шифр (марка) опоры
                th Тип опоры
                th Длина пролета до следующей опоры (по паспорту) (м)
                th Длина пролета до следующей опоры (расчетное) (м)
                th Угол поворота трассы (град)
                th Кадастровый номер земельного участка
                th Расстояние от опоры до земельного участка (м)
                th Наименование ЛУ
                th Наименование ПМЭС
                th Наименование МЭС
                th Смотреть на карте
            tbody
              tr(v-for="(item, j) of list" :key="j" :class="{ active : item.properties.id === table.activeRow }")
                td {{ j + 1 }}
                td {{ item.properties.name }}
                td {{ item.properties.cypher }}
                td {{ item.properties.type }}
                td {{ item.properties.lengthNextPassport }}
                td {{ item.properties.lengthNextPlanar }}
                td {{ item.properties.angle ?? '-' }}
                td {{ item.properties.cadNum ?? '-' }}
                td {{ Number(item.properties.cadDistance) ?? '-' }}
                td {{ item.properties.LU ?? '-' }}
                td {{ item.properties.PMES ?? '-' }}
                td {{ item.properties.MES ?? '-' }}
                td
                  .table__marker(@click="flyToPivot(item.properties.id)")
                    svg(width="12" height="16" viewBox="0 0 12 16")
                      path(d="M6 15C4 12 1 9 1 6C1 3 3 1 6 1C9 1 11 3 11 6C11 9 8 12 6 15Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
</template>

<script>
import { useTable } from '@/js/use/table'

export default {
  setup () {
    return {
      ...useTable()
    }
  }
}
</script>
