<template lang="pug">
.map__search.mapSearch
  .mapSearch__dropdown.dropdown(
    :class="{ active: dropdown.open }"
    @focusout="dropdown.open = false" tabindex="0"
  )
    .dropdown__input(@click="dropdownToggle")
      .dropdown__selected {{ dropdownSelected.title }}
      .dropdown__arrow
        svg(width="13" height="7" viewBox="0 0 13 7")
          path(d="M5.59923 6.75926C5.97372 7.08025 6.52632 7.08025 6.90081 6.75926L12.1508 2.25926C12.5701 1.89983 12.6187 1.26853 12.2593 0.849209C11.8999 0.429883 11.2686 0.381321 10.8492 0.740743L6.25002 4.68292L1.65081 0.740743C1.23148 0.381321 0.600184 0.429883 0.240762 0.849209C-0.118661 1.26853 -0.0700991 1.89983 0.349227 2.25926L5.59923 6.75926Z" fill="#00AB59")
    .dropdown__list
      .dropdown__item(
        v-for="(item, index) in dropdown.list"
        :key="index"
        :class="index === dropdown.active ? 'active' : ''"
        v-text="item.title"
        @click="dropdownSelect(index)"
      )
  .mapSearch__input.inputSearch(
    :class="{ active: inputSearch.open }"
    @focusout="inputSearch.open = false" tabindex="0"
    @focusin="inputSearch.results.length ? inputSearch.open = true : ''"
  )
    .inputSearch__inner
      input(
        type="text" spellcheck="false" placeholder="Поиск..."
        @input="searchMap"
        @keyup.enter="searchEnterResult"
        v-model.trim="inputSearch.keywords"
      )
      button(:disabled="!inputSearch.results.length" @click="searchEnterResult")
        svg(width="25" height="12" viewBox="0 0 25 12")
          path(d="M17.6402 0.231781L24.562 6L17.6402 11.7682L16.3598 10.2318L20.2379 7H0V5H20.2379L16.3598 1.76822L17.6402 0.231781Z" fill="white")
    .inputSearch__results
      .inputSearch__item(
        v-for="(item, index) in inputSearch.results"
        :key="index"
        @click="searchClickResult(index)"
      ) {{ item.address || item.name }}
        span(v-if="item.type") {{ item.type }}
      .inputSearch__noResults(v-if="inputSearch.keywords.length > inputSearch.minLength && !inputSearch.results.length") Ничего не найдено
</template>

<script>
import { useSearch } from '@/js/use/search'

export default {
  emits: ['infoObject', 'clearFeature'],
  setup () {
    return {
      ...useSearch()
    }
  }
}
</script>
