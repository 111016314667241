import { inject, ref } from 'vue'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import { area as turfArea, lineDistance as turfLine, polygonToLine } from '@turf/turf'

export const useControl = () => {
  const map = inject('map')
  const showSatellite = ref('visible')
  const mapPaddingLeft = inject('mapPaddingLeft')
  const toggleSatellite = (show) => {
    showSatellite.value = show
      ? 'visible'
      : 'none'
    map.value.setLayoutProperty('satellite', 'visibility', showSatellite.value)
  }

  const zoomIn = () => map.value.zoomIn()
  const zoomOut = () => map.value.zoomOut()
  const bearingSouth = () => map.value.setBearing(0)

  const draw = new MapboxDraw({
    displayControlsDefault: false
  })
  map.value.addControl(draw)
  const drawLine = () => draw.changeMode('draw_line_string')
  const drawPoly = () => draw.changeMode('draw_polygon')
  const coordPoint = () => draw.changeMode('draw_point')

  const trash = () => draw.trash()

  const print = () => window.print()

  const showMeasure = (data) => {
    const { features } = data
    if (features.length) {
      const feature = features[features.length - 1]
      const { type, coordinates } = feature.geometry

      if (type === 'Point') {
        return {
          type: 'point',
          values: {
            lng: Math.round(coordinates[0] * 1_000_000) / 1_000_000,
            lat: Math.round(coordinates[1] * 1_000_000) / 1_000_000
          }
        }
      }
      if (type === 'LineString') {
        const lenghtLine = turfLine(feature, { units: 'kilometers' })
        return {
          type: 'line',
          values: {
            l: (lenghtLine > 1)
              ? `${Math.round(lenghtLine * 100) / 100} км`
              : `${Math.round(lenghtLine * 1000 * 100) / 100} м`
          }
        }
      }
      if (type === 'Polygon') {
        const area = turfArea(feature)
        const perimetr = turfLine(polygonToLine(feature), { units: 'kilometers' })
        return {
          type: 'polygon',
          values: {
            s: (area > 100000)
              ? `${Math.round(area / 1000000 * 100) / 100} км<sup>2</sup>`
              : `${Math.round(area * 100) / 100} м<sup>2</sup>`,
            p: (perimetr > 1)
              ? `${Math.round(perimetr * 100) / 100} км`
              : `${Math.round(perimetr * 1000 * 100) / 100} м`
          }
        }
      }
      return {}
    }
  }
  const measure = ref({})
  const route = ref(false)
  const drawMode = inject('drawMode')
  const drawModeOff = inject('drawModeOff')
  const routeToggle = () => {
    route.value = !route.value
  }
  const routeOff = () => {
    if (route.value) {
      route.value = false
    }
    if (drawMode.value) {
      drawModeOff()
    }
  }
  const updateMeasure = () => {
    measure.value = showMeasure(draw.getAll())
  }
  const selectMeasure = () => {
    measure.value = showMeasure(draw.getSelected())
  }
  map.value.on('draw.create', updateMeasure)
  map.value.on('draw.delete', updateMeasure)
  map.value.on('draw.update', updateMeasure)
  map.value.on('draw.selectionchange', selectMeasure)

  return {
    measure, mapPaddingLeft, showSatellite, toggleSatellite, zoomIn, zoomOut, bearingSouth, drawLine, drawPoly, coordPoint, trash, print, route, routeOff, routeToggle
  }
}
