import {
  DATA_GET,
  DATA_OBJECT,
  DATA_SET,
  DATA_SEARCH,
  DATA_SUCCESS,
  DATA_ERROR,
  DATA_SET_OBJECT,
  DATA_PIVOT_LIST
} from '@/store/actions/data'
import { getData } from '@/js/utils/api'

const state = {
  status: '',
  collections: {},
  object: {
    data: null,
    type: null
  },
  searchResult: [],
  pivotList: []
}
const getters = {
  getObject: state => state.object,
  getCollection: state => typeCollection => {
    if (typeCollection === 'stationsPoints') {
      const { features } = state.collections.find(col => col.properties._t === 'stations')
      const stations = []
      features.forEach(item => {
        stations.push({
          type: item.type,
          geometry: item.properties.center,
          properties: item.properties
        })
      })
      return {
        type: 'FeatureCollection',
        features: stations
      }
    }
    return state.collections.find(col => col.properties._t === typeCollection)
  },
  getLineById: state => id => {
    const { features } = state.collections.find(col => col.properties._t === 'lines')
    return features.find(item => item.properties.id === id)
  },
  getObjectById: state => (id, collection) => {
    const { features } = state.collections.find(col => col.properties._t === collection)
    return features.find(item => item.properties.id === id)
  },
  searchObjects: (state, commit) => (keywords) => {
    let searchObjects = []
    const searchCollection = ['lines', 'stations']
    const searchWords = keywords.split(' ').map(word => word.trim())
    const reg = new RegExp(searchWords.join('|'), 'gi')
    searchCollection.forEach(collection => {
      const { features } = state.collections.find(col => col.properties._t === collection)
      searchObjects = searchObjects.concat(
        features.filter(item => {
          return (item.properties.name.match(reg) || []).length >= searchWords.length
        })
      )
    })
    return searchObjects
  },
  searchLand: (state) => (keywords) => {
    const { features } = state.collections.find(col => col.properties._t === 'zu')
    return features
      .filter(item => item.properties.cad.includes(keywords.trim()))
      .slice(0, 7) // обрезаем массив
  },
  dataStatus: state => state.status
}

const actions = {
  [DATA_GET]: ({ commit }, url) => {
    return new Promise((resolve, reject) => {
      commit(DATA_GET)
      getData('/map_api/all')
        .then(resp => {
          commit(DATA_SET, resp)
          commit(DATA_SUCCESS)
          resolve(resp)
        })
        .catch(err => {
          commit(DATA_ERROR, err)
          reject(err)
        })
    })
  },
  [DATA_OBJECT]: ({ commit }, { type, id }) => {
    commit(DATA_GET)
    getData(`/map_api/${type}/${id}`)
      .then(resp => {
        commit(DATA_SET_OBJECT, resp, type)
        commit(DATA_SUCCESS)
      })
      .catch(err => commit(DATA_ERROR, err))
  },
  [DATA_PIVOT_LIST]: ({ commit }, id) => {
    commit(DATA_GET)
    getData(`/map_api/lines_passport/${id}`)
      .then(resp => {
        commit(DATA_PIVOT_LIST, resp)
        commit(DATA_SUCCESS)
      })
      .catch(err => commit(DATA_ERROR, err))
  },
  [DATA_SEARCH]: ({ commit }, data) => {
    commit(DATA_SEARCH, data)
  }
}

const mutations = {
  [DATA_GET]: state => {
    state.status = 'loading'
  },
  [DATA_SEARCH]: (state, data) => {
    state.searchResult = data
  },
  [DATA_SET]: (state, resp) => {
    state.collections = resp
  },
  [DATA_PIVOT_LIST]: (state, resp) => {
    state.pivotList = resp.supports
  },
  [DATA_SET_OBJECT]: (state, resp, type = '') => {
    state.object = {
      type,
      data: resp
    }
  },
  [DATA_ERROR]: state => {
    state.status = 'error'
  },
  [DATA_SUCCESS]: state => {
    state.status = 'success'
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
