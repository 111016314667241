import { onMounted, ref, reactive, provide } from 'vue'
import mapboxgl from 'mapbox-gl'
import { layersList } from '@/js/map/layersList'
import { store } from '@/store'
import { DATA_OBJECT } from '@/store/actions/data'
import { centroid } from '@turf/turf'

import station from '@/assets/station.png'
import substation from '@/assets/substation.png'

export function useMap (init) {
  const map = ref(null)
  const dataLoading = ref(false)
  const mapPaddingLeft = ref(0)
  const drawMode = ref(false)
  const layersFilter = ref([])
  const mapParams = reactive({
    hover: {
      id: null,
      source: null
    },
    click: {
      id: null,
      hover: null
    },
    popup: null
  })
  const featureActive = (feature = null) => {
    if (mapParams.click.id !== null) {
      map.value.setFeatureState(
        { source: mapParams.click.source, id: mapParams.click.id },
        { clicked: false }
      )
    }
    if (feature === null) {
      mapParams.click = {
        id: null,
        source: null
      }
      return
    }
    mapParams.click = {
      id: feature.id,
      source: feature.source
    }
    map.value.setFeatureState(
      { source: feature.source, id: feature.id },
      { clicked: true }
    )
  }
  const mapInit = onMounted(async () => {
    mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN
    map.value = new mapboxgl.Map({
      container: init.container,
      style: 'mapbox://styles/perr1n/cknjo8v3l01yi17pm6o5d65ng',
      // style: 'mapbox://styles/perr1n/cknk50o3b1trc17nk8o0y217e',
      bounds: [
        [84.447894700, 52.162703500],
        [89.402643700, 56.834420200]
      ],
      maxZoom: 19.3
    })
    map.value.on('load', () => {
      const clickOnObjects = (e, features, collection) => {
        if (mapParams.popup) {
          mapParams.popup.remove()
        }
        if (features.length > 1) {
          const description = []
          features.forEach(item => {
            const jsonFeature = JSON.stringify({ id: item.id, layer: { source: item.layer.source } })
            if (item.source === 'powerPoints') {
              description.push(`
          <div class="popupPower__object" data-id="${item.properties.id}" data-collection="${collection}" data-feature=${jsonFeature}>
              Опора № ${item.properties.name}
              <b>${store.getters.getObjectById(item.properties.power_line_id, 'lines').properties.name}</b>
          </div>
        `)
            } else {
              description.push(`
          <div class="popupPower__object" data-id="${item.properties.id}" data-collection="${collection}" data-feature=${jsonFeature}>
              ${item.properties.name}
              <b><b>Линия электропередачи</b></b>
          </div>
        `)
            }
          })
          mapParams.popupClick
            .setLngLat(e.lngLat)
            .setHTML(description.join('\n'))
            .addTo(map.value)
          mapParams.popupClick
            .getElement()
            .getElementsByClassName('popupPower__object')
            .forEach(item => item.addEventListener('click', e => clickOnObject(e), { once: true }))
        } else {
          openSidebar(features, collection)
        }
      }
      const clickOnObject = (e) => {
        const id = e.currentTarget.getAttribute('data-id')
        const collection = e.currentTarget.getAttribute('data-collection')
        const feature = { ...store.getters.getObjectById(Number(id), collection), ...JSON.parse(e.currentTarget.getAttribute('data-feature')) }
        if (typeof feature === 'object') {
          openSidebar([feature], collection)
        }
        if (mapParams.popupClick) {
          mapParams.popupClick.remove()
        }
      }
      const openSidebar = (features, collection) => {
        // const coordinates = features[0].geometry.type === 'Point'
        //   ? features[0].geometry.coordinates
        //   : bbox(features[0])
        // const coordinates = features[0].geometry.type === 'Point'
        //   ? features[0].geometry.coordinates
        if (features[0].geometry.type === 'Point') {
          setTimeout(() => {
            map.value.flyTo({
              padding: { left: mapPaddingLeft.value },
              center: features[0].geometry.coordinates,
              zoom: 16.5
            })
          }, 500)
        } else {
          // map.value.fitBounds(coordinates, { padding: 30 })
        }
        collection = (collection === 'stationsPoints') ? 'stations' : collection
        store.dispatch(DATA_OBJECT, { type: collection, id: features[0].properties.id })

        if (mapParams.click.id !== null) {
          map.value.setFeatureState(
            { source: mapParams.click.source, id: mapParams.click.id },
            { clicked: false }
          )
        }
        mapParams.click = {
          id: features[0].id,
          source: features[0].layer.source
        }
        map.value.setFeatureState(
          { source: features[0].layer.source, id: features[0].id },
          { clicked: true }
        )
      }
      const clickCluster = (features) => {
        map.value.getSource(features[0].layer.source).getClusterExpansionZoom(
          features[0].properties.cluster_id,
          function (err, zoom) {
            if (err) return

            map.value.easeTo({
              padding: { left: mapPaddingLeft.value },
              center: features[0].geometry.coordinates,
              zoom: zoom
            })
          }
        )
      }
      const mousemoveFeature = (e, popup = {}) => {
        if (drawMode.value) {
          return
        }
        map.value.getCanvas().style.cursor = 'pointer'
        const format = (val, n, s = 0) => Math.round(val * 10 ** n) / (10 ** n) / (10 ** s)
        if (e.features.length > 0 && !mapParams.popupClick.isOpen()) {
          const features = e.features.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
          const description = []
          if (!!Object.keys(popup).length || !popup) {
            features.forEach(({ geometry, properties }) => {
              const nameLine = properties.power_line_id
                ? `<b>${store.getters.getObjectById(properties.power_line_id, 'lines').properties.name}</b>`
                : ''
              Object.keys(popup).forEach((prop, index) => {
                if (prop === 'coordinates') {
                  const coordinates = geometry.type === 'Point'
                    ? geometry.coordinates
                    : centroid(e.features[0]).geometry.coordinates
                  description.push(`<div class="popupPower__item"><b>Координаты:</b> ${format(coordinates[0], 6)}, ${format(coordinates[1], 6)}</div>`)
                } else if (properties.power_line_id && prop === 'name' && properties[prop] === 'null') {
                  description.push(`<div class="popupPower__title">Опора ЛЭП ${nameLine}</div>`)
                } else {
                  const propValue = prop === 'lengthPassport'
                    ? properties[prop]
                      ? `${format(properties[prop], 2, 3)} км`
                      : 'нет данных'
                    : properties[prop] == null || properties[prop] === ''
                      ? '-'
                      : properties[prop]
                  description.push(index
                    ? `<div class="popupPower__item"><b>${popup[prop]}:</b> ${propValue ?? '-'}</div>`
                    : `<div class="popupPower__title">
                    ${popup[prop]}${propValue ?? '-'}
                    ${nameLine}
                </div>`
                  )
                }
              })
            })
            mapParams.popup
              .setLngLat(e.lngLat)
              .setHTML(description.join('\n'))
              .addTo(map.value)
          }

          if (mapParams.hover.id !== null) {
            map.value.setFeatureState(
              {
                source: mapParams.hover.source,
                id: mapParams.hover.id
              },
              { hover: false }
            )
          }
          mapParams.hover = {
            id: e.features[0].id,
            source: e.features[0].layer.source
          }
          map.value.setFeatureState(
            {
              source: e.features[0].layer.source,
              id: e.features[0].id
            },
            { hover: true }
          )
        }
      }
      const mouseleaveFeature = () => {
        if (drawMode.value) {
          return
        }
        map.value.getCanvas().style.cursor = ''
        if (mapParams.hover.id !== null) {
          map.value.setFeatureState(
            { source: mapParams.hover.source, id: mapParams.hover.id },
            { hover: false }
          )
        }
        mapParams.hover.id = null
        if (mapParams.popup) {
          mapParams.popup.remove()
        }
      }
      map.value.addLayer({
        id: 'satellite',
        type: 'raster',
        source: {
          type: 'raster',
          tiles: ['https://mt0.google.com/vt/lyrs=y&hl=ru&x={x}&y={y}&z={z}'],
          tileSize: 256
        },
        layout: {
          visibility: 'visible'
        }
      })
      // eslint-disable-next-line no-unused-expressions
      const onMap = async () => {
        await addImages(map.value, [
          { id: 'station', url: station },
          { id: 'substation', url: substation }
        ])
        await addIcons(map.value, [
          { id: 'square-220', size: 16, color: '#07C5C5' },
          { id: 'square-500', size: 16, color: '#FF8A00' },
          { id: 'square', size: 20, color: '#ffffff' },
          { id: 'square-selected', size: 20, color: '#FFFA7E' }
        ])
        mapParams.popup = new mapboxgl.Popup({
          closeButton: false,
          className: 'popupPower',
          maxWidth: 240,
          offset: 10
        })
        mapParams.popupClick = new mapboxgl.Popup({
          closeButton: false,
          className: 'popupPower popupPower--click',
          maxWidth: 240,
          offset: 10
        })
        const clickLayers = []
        const sources = await layersList()
        await Object.keys(sources).forEach(nameSource => {
          map.value.addSource(nameSource, sources[nameSource].options)
          if (sources[nameSource].filter) {
            layersFilter[nameSource] = []
          }
          for (const layer of sources[nameSource].layers) {
            map.value.addLayer(layer)
            if (sources[nameSource].filter) {
              layersFilter.value.push({
                id: layer.id,
                filter: layer.filter || false
              })
            }
            const options = layer.options ?? { hover: false, click: false, popup: {} }
            const hover = options.hover ?? false
            const click = options.click ?? false
            const popup = options.popup ?? {}
            if (hover) {
              map.value.on('mousemove', layer.id, e => mousemoveFeature(e, popup))
              map.value.on('mouseleave', layer.id, () => mouseleaveFeature())
            }
            if (click) {
              clickLayers.unshift({
                click,
                layerId: layer.id,
                collection: sources[nameSource].collectionName
              })
            }
          }
        })
        dataLoading.value = true
        map.value.on('click', e => {
          if (drawMode.value) {
            return
          }
          let features = []
          if (mapParams.click.id !== null) {
            map.value.setFeatureState(
              { source: mapParams.click.source, id: mapParams.click.id },
              { clicked: false }
            )
            mapParams.click = {
              id: null,
              source: null
            }
          }
          for (const item of clickLayers) {
            features = map.value.queryRenderedFeatures(e.point, { layers: [item.layerId] })
            features = features.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
            if (features.length) {
              if (item.click === 'cluster') {
                clickCluster(features)
              } else if (item.click === 'sidebar') {
                clickOnObjects(e, features, item.collection)
              }
              break
            }
          }
        })
      }
      onMap()
    })
  })
  const addMapPaddingLeft = () => {
    mapPaddingLeft.value = 410
  }
  const removeMapPaddingLeft = () => {
    mapPaddingLeft.value = 0
  }
  const drawModeOn = () => {
    drawMode.value = true
  }
  const drawModeOff = () => {
    drawMode.value = false
  }
  provide('map', map)
  provide('drawMode', drawMode)
  provide('drawModeOn', drawModeOn)
  provide('drawModeOff', drawModeOff)
  provide('mapPaddingLeft', mapPaddingLeft)
  provide('addMapPaddingLeft', addMapPaddingLeft)
  provide('removeMapPaddingLeft', removeMapPaddingLeft)
  return {
    map,
    mapInit,
    dataLoading,
    layersFilter,
    featureActive
  }
}

function addImages (map, images) {
  const addImage = (map, id, url) => {
    return new Promise((resolve, reject) => {
      map.loadImage(url, (error, image) => {
        if (error) {
          reject(error)
          return
        }
        map.addImage(id, image)
        resolve(image)
      })
    })
  }
  const promises = images.map(imageData => addImage(map, imageData.id, imageData.url))
  return Promise.all(promises)
}

function addIcons (map, icons) { // id, rgba = [255, 138, 0, 255]
  const bytesPerPixel = 4
  const hexToRGB = color => {
    color = color.substring(0, 1) === '#' ? color.substring(1) : color
    return {
      r: parseInt(color.substring(0, 2), 16),
      g: parseInt(color.substring(2, 4), 16),
      b: parseInt(color.substring(4), 16)
    }
  }
  icons.forEach(icon => {
    const { id, size, color } = icon
    const data = new Uint8Array(size * size * bytesPerPixel)
    const rgb = hexToRGB(color)
    for (let x = 0; x < size; x++) {
      for (let y = 0; y < size; y++) {
        const offset = (y * size + x) * bytesPerPixel
        data[offset + 0] = rgb.r
        data[offset + 1] = rgb.g
        data[offset + 2] = rgb.b
        data[offset + 3] = 255
      }
    }
    map.addImage(id, { width: size, height: size, data: data })
  })
}
