import axios from 'axios'
// import download from 'downloadjs'

export const getToken = ({ username, password }) => {
  return axios({
    method: 'POST',
    url: `${process.env.VUE_APP_API_URL}/api/login`,
    params: {
      login: username.value,
      password: password.value
    },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }).then(response => response.data)
}

export const getProfil = async (url) => {
  await setTimeout(() => { }, 1000)
  return { name: 'Тимофеев А.Е.' }
}

export const getData = (url) => {
  const token = localStorage.getItem('user-token')
  const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: { Authorization: `Bearer ${token}` }
  })
  return instance.get(url).then(response => response.data)
}
export const routingCar = (coordinates) => {
  return axios({
    method: 'POST',
    url: 'https://api.openrouteservice.org/v2/directions/driving-car/geojson',
    data: {
      coordinates: coordinates,
      radiuses: [10000, 10000]
    },
    headers: {
      Authorization: '5b3ce3597851110001cf62486c53c0fd5c9442dbbbb9737306b8e3f6',
      Accept: 'application/json, application/geo+json, application/gpx+xml, img/png; charset=utf-8',
      'Content-Type': 'application/json; charset=utf-8'
    }
  }).then(response => response.data)
}
export const downloadFile = (file) => {
  const token = localStorage.getItem('user-token')
  fetch(file.href, {
    method: 'GET',
    headers: new Headers({
      Authorization: 'Bearer ' + token
    })
  })
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = file.name
      document.body.appendChild(a)
      a.click()
      a.remove()
    })
}
