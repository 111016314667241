<template lang="pug">
.map__legend.mapLegend
  .mapLegend__toogle(@click="toggle" :class="{hideLegeng : !visible}")
  .mapLegend__title Объекты ЭСХ
  .mapLegend__inner(v-if="visible")
    .mapLegend__item.mapLegend__item--groupe
      ul
        li
          .mapLegend__icon
            img(src='~@/assets/legend-plants.svg')
          | электростанции
        li
          .mapLegend__icon
            img(src='~@/assets/legend-line.svg')
          | линии
        li
          .mapLegend__icon
            img(src='~@/assets/legend-support.svg')
          | опоры
        li
          .mapLegend__icon
            img(src='~@/assets/legend-substations.svg')
          | подстанции
    .mapLegend__item.mapLegend__item--single(v-for="(item, index) in legend" :key="index" @click="toggleLayers(index)")
      .mapLegend__checkbox(:class="{active: item.active}")
      span {{ item.title }}
      svg(viewBox="0 0 12 12")
        circle(cx="6" cy="6" r="6" :fill="item.color")
</template>

<script>
import { useLegend } from '@/js/use/legend'
export default {
  props: ['layersFilter'],
  setup (props) {
    return {
      ...useLegend(props)
    }
  }
}
</script>
