<template lang="pug">
.sidebar(:class="{ active: sidebar.open }")
  Search(@info-object="infoObject" @clear-feature="clearFeature")
  transition(name="fade")
    .sidebar__inner(v-if="sidebar.open")
      slot(v-if="sidebar.data")
        .sidebar__title
          h3 {{ sidebar.data.name }}
          span(v-if="sidebar.data.coordinates") Координаты: {{ sidebar.data.coordinates }}
        .sidebar__content.information
          perfect-scrollbar
            .information__caption Информация по объекту
            .information__item(v-for="(field, index) in sidebar.data.content" :key="index")
              .information__button(v-if="field.fileUrl")
                b {{ field.title }}
                a(:href="field.fileUrl" @click.prevent="download({ href: field.fileUrl, name: field.fileName })" class="button") скачать
              .information__button(v-else-if="field.table")
                a(class="button button--list" @click="openTable(field.table)")
                  svg(width="16" height="10" viewBox="0 0 16 10")
                    line(y1="1" x2="16" y2="1" stroke-width="2")
                    line(y1="5" x2="16" y2="5" stroke-width="2")
                    line(y1="9" x2="16" y2="9" stroke-width="2")
                  | Поопорная ведомость
              span(v-else-if="field.value")
                b {{ field.title }}
                | {{ field.value }}
      .sidebar__search.searchResult(v-else-if="sidebar.search")
        .searchResult__count Найдено: {{ sidebar.search.length }}
        .searchResult__list
          perfect-scrollbar
            .searchResult__item(
              v-for="(item, index) in sidebar.search"
              :key="index"
              @click="infoObject(item)"
            ) {{ item.name }}
              span {{ item.type }}
</template>

<script>
import Search from '@/components/Search'
import { useSidebar } from '@/js/use/sidebar'
export default {
  components: {
    Search
  },
  emits: ['featureActive', 'clearFeature'],
  setup () {
    return {
      ...useSidebar()
    }
  }
}
</script>
