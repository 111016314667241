export function fieldsList ({ geometry, properties }) {
  if (properties._t === 'substation') {
    const file = properties.files.length ? properties.files[0] : ''
    return {
      name: properties.name,
      coordinates: (geometry.type === 'Point') ? coordToString(geometry.coordinates) : coordToString(properties.center.coordinates),
      content: [
        { title: 'Высший класс напряжения, кВ', value: reformatNumber(properties.maxVoltage, 0, 3) },
        { title: 'Напряжение, кВ', value: properties.voltage ?? '-' },
        { title: 'Установленная мощность, МВА', value: properties.power ?? '-' },
        { title: 'Год ввода в эксплуатацию', value: properties.startYear ?? '-' },
        { title: 'Адрес', value: properties.address ?? '-' },
        { title: 'Кадастровый номер земельного участка', value: properties.cadNum ?? '-' },
        { title: 'Собственник объекта', value: properties.owner ?? '-' },
        { title: 'Год последней реконструкции', value: properties.reworkYear ?? '-' },
        { title: 'Планируемый год реконстукции', value: properties.reworkPlan ?? '-' },
        { title: 'Однолинейная схема', fileUrl: file.href, fileName: file.name }
      ]
    }
  } else if (properties._t === 'station') {
    return {
      name: properties.name,
      coordinates: (geometry.type === 'Point') ? coordToString(geometry.coordinates) : coordToString(properties.center.coordinates),
      content: [
        { title: 'Адрес', value: properties.address ?? '-' },
        { title: 'Кадастровый номер земельного участка', value: properties.cadNum ?? '-' },
        { title: 'Год ввода в эксплуатацию', value: properties.startYear ?? '-' },
        { title: 'Собственник объекта', value: properties.owner ?? '-' },
        { title: 'Установленная электрическая мощность, МВт', value: properties.power ?? '-' },
        { title: 'Установленная тепловая мощность, Гкал/ч', value: properties.heat ?? '-' },
        { title: 'Вид топлива основной', value: properties.fuelMain ?? '-' },
        { title: 'Вид топлива резервный', value: properties.fuelSecondary ?? '-' }
      ]
    }
  } else if (properties._t === 'point') {
    return {
      name: (properties.name != null) ? `Опора № ${properties.name}` : 'Опора ЛЭП',
      coordinates: coordToString(geometry.coordinates),
      content: [
        { title: 'Кадастровый номер земельного участка', value: properties.cadNum ?? '-' },
        { title: 'Тип опоры', value: properties.type ?? '-' },
        { title: 'Длина пролета до следующей опоры (по паспорту), м', value: reformatNumber(properties.lengthNextPassport, 0, 0) ?? '-' },
        { title: 'Собственник объекта', value: properties.owner ?? '-' }
      ]
    }
  } else if (properties._t === 'line') {
    return {
      name: properties.name,
      content: [
        { title: 'Мощность, кВ', value: reformatNumber(properties.voltage, 0, 3) ?? '-' },
        { title: 'Исполнение ЛЭП', value: properties.chain ?? '-' },
        { title: 'Протяженность линии, км', value: reformatNumber(properties.lengthPassport, 0, 3) ?? '-' },
        { title: 'Год ввода в эксплуатацию', value: properties.startYear ?? '-' },
        { title: 'Марка провода', value: properties.wireType ?? '-' },
        { title: 'Собственник объекта', value: properties.owner ?? '-' },
        { title: 'Год последней реконструкции', value: properties.reworkYear ?? '-' },
        { title: 'Планируемый год реконстукции', value: properties.reworkPlan ?? '-' },
        { title: 'Примечание', value: properties.note ?? '-' },
        { title: null, table: properties.id }
      ]
    }
  }
}

function reformatNumber (val, a, sep = 0) {
  if (val == null) return
  val = Number(val)
  return (a !== 0)
    ? (Math.round(val * 10 ** a) / (10 ** a)) / (10 ** sep)
    : val / (10 ** sep)
}
function coordToString (val) {
  return [reformatNumber(val[0], 6), reformatNumber(val[1], 6)].join(', ')
}
